import { Component, ViewChild, OnInit, HostListener, SecurityContext } from '@angular/core';
import { FormControl } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import { DashboardService } from '../../services/dashboard.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../../services/alert.service';
import { PrintSearchReportComponent } from './printSearchReport.component';
import { DashboardDataServiceService } from '../../services/dashboard-data-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OktaAuthService } from '@okta/okta-angular';


@Component({
    selector: 'ngx-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI

    displayedColumns = ['Delete', 'loG_CODE', 'totalLogs', 'tapE_TITLE', 'subtitle', 'location', 'aiR_DATE', 'shooT_DATE', 'reeL_NUMBER',
        'venue', 'director', 'producer', 'loggeD_BY', 'other', 'showCode'];
    dataSource: any = new MatTableDataSource<DataTape>();

    displayedColumnsForDataTapeLog = ['Delete', 'loG_CODE', 'totalLogs', 'tapE_TITLE', 'aiR_DATE', 'code', 'time', 'story', 'id'];
    dataSourceForDataTapeLog: any = new MatTableDataSource<DataTapeLog>();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    public isAdmin = false;
    sortAirDateLogs = true;
    sortShootDate = true;
    sortAirDateTextOrCode = true;
    totalCount = 0;
    totalCountLog = 0;
    limit = 40;
    offset = 0;
    endSearch = false;
    DataTapeLogLimit = 40;
    DataTapeLogOffset = 0;
    DataTapeLogendSearch = false;
    datalist: any = [];
    datalistDataTapeLogend: any = [];
    windowMaxHeight = 500;
    windowMaxWidth = 500;

    obj: any = {};
    logcode: any = "";
    tapeTitle: any = "";
    loggedBy: any = "";
    subtitle: any = "";
    producer: any = "";
    venue: any = "";
    director: any = "";
    loglocation: any = "";
    other: any = "";
    reelNumber: any = "";
    showCode: any = "";
    airDate: any = null;
    shootDate: any = null;
    selectedLog: any = "Logs";
    searchType: any = "AND";
    BytextCode: any = true;
    storyCode: any = "";
    shootFrom1: any = null;
    shootFrom2: any = null;
    shootFrom3: any = null;
    shootFrom4: any = null;
    shootTo1: any = null;
    shootTo2: any = null;
    shootTo3: any = null;
    shootTo4: any = null;
    IsPrintDisable = true;
    IsMultipleDeleteSelected = false;
    LogIds = [];
    LogDelMultiSelected = false;
    LogTextDelMultiSelected = false;
    showIsDeleted: boolean = false;
    IsDeletedFlag: boolean = false;

    producerSearchResult: any = [];
    producerSearchTerm: FormControl = new FormControl();

    directorSearchResult: any = [];
    directorSearchTerm: FormControl = new FormControl();
    public isAuthenticated: boolean;

    constructor(public oktaAuth: OktaAuthService,public dashboardService: DashboardService, private router: Router, public authenticationService: AuthenticationService,
        private toastr: ToastrService, public alertService: AlertService, public dialog: MatDialog, public dashboardDataService: DashboardDataServiceService, private sanitizer: DomSanitizer) {

        // subscribe to authentication state changes
        this.oktaAuth.$authenticationState.subscribe(
            (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
        );

        authenticationService.getCurrentAuthentication().subscribe((data) => {
            if (data != null) {
                this.isAdmin = (<any>data).isAdmin;
            }
        });

        if (this.dashboardDataService.isSearch === true || this.dashboardDataService.isDelete === true) {
            this.AssignSearchDetails();
        }

        //Producer change event
        this.producerSearchTerm.valueChanges
            .debounceTime(400)
            .subscribe(data => {
                this.dashboardService.Search(data, 'producer').subscribe(x => {
                    this.producerSearchResult = x;
                });
            });

        //Director change event
        this.directorSearchTerm.valueChanges
            .debounceTime(400)
            .subscribe(data => {
                this.dashboardService.Search(data, 'director').subscribe(x => {
                    this.directorSearchResult = x;
                });
            });
    }

    AssignSearchDetails() {
        this.logcode = this.dashboardDataService.logcode;
        this.tapeTitle = this.dashboardDataService.tapeTitle;
        this.loggedBy = this.dashboardDataService.loggedBy;
        this.subtitle = this.dashboardDataService.subtitle;
        this.producer = this.dashboardDataService.producer;
        this.venue = this.dashboardDataService.venue;
        this.director = this.dashboardDataService.director;
        this.loglocation = this.dashboardDataService.location;
        this.logcode = this.dashboardDataService.logcode;
        this.other = this.dashboardDataService.other;
        this.reelNumber = this.dashboardDataService.reelNumber;
        this.showCode = this.dashboardDataService.showCode;
        this.airDate = this.dashboardDataService.airDate;
        this.shootDate = this.dashboardDataService.shootDate;

        this.selectedLog = this.dashboardDataService.selectedLog;
        this.searchType = this.dashboardDataService.searchType;
        this.BytextCode = this.dashboardDataService.BytextCode;
        this.storyCode = this.dashboardDataService.storyCode;
        this.shootFrom1 = this.dashboardDataService.shootFrom1;
        this.shootFrom2 = this.dashboardDataService.shootFrom2;
        this.shootFrom3 = this.dashboardDataService.shootFrom3;
        this.shootFrom4 = this.dashboardDataService.shootFrom4;
        this.shootTo1 = this.dashboardDataService.shootTo1;
        this.shootTo2 = this.dashboardDataService.shootTo2;
        this.shootTo3 = this.dashboardDataService.shootTo3;
        this.shootTo4 = this.dashboardDataService.shootTo4;
        this.showIsDeleted = this.dashboardDataService.showIsDeleted;

        this.limit = this.dashboardDataService.limit;
        this.offset = this.dashboardDataService.offset;
        this.endSearch = this.dashboardDataService.endSearch;
        this.DataTapeLogLimit = this.dashboardDataService.DataTapeLogLimit;
        this.DataTapeLogOffset = this.dashboardDataService.DataTapeLogOffset;
        this.DataTapeLogendSearch = this.dashboardDataService.DataTapeLogendSearch;

        this.datalist = this.dashboardDataService.datalist;
        this.dataSource = new MatTableDataSource(this.datalist);
        this.dataSource.sort = this.sort;

        this.datalistDataTapeLogend = this.dashboardDataService.datalistDataTapeLogend;
        this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
        this.dataSourceForDataTapeLog.sort = this.sort;


        this.totalCount = this.dashboardDataService.totalCount;
        this.totalCountLog = this.dashboardDataService.totalCountLog;

        this.IsPrintDisable = this.dashboardDataService.IsPrintDisable;

        if (this.dashboardDataService.isDelete === true) {
            this.getSearch();
        }

    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
        this.getWindowHeight();
        this.getWindowWidth();
    }

    getSearch() {
        if (this.selectedLog == "Logs") {
            this.limit = 40;
            this.offset = 0;
            this.endSearch = false;
            this.datalist = [];
            this.dataSource = new MatTableDataSource(this.datalist);
            this.totalCount = 0;
        }
        else {
            this.DataTapeLogLimit = 40;
            this.DataTapeLogOffset = 0;
            this.DataTapeLogendSearch = false;
            this.datalistDataTapeLogend = [];
            this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
            this.totalCountLog = 0;
        }

        this.LogDelMultiSelected = false;
        this.LogTextDelMultiSelected = false;

        this.getTapeData(true);

    }
    getTapeData(loadUi) {
        if (loadUi == true) {
            this.blockUI.start();
        }
        this.obj = {
            logCode: this.logcode,
            tapeTitle: this.tapeTitle,
            loggedBy: this.loggedBy,
            subtitle: this.subtitle,
            producer: this.producerSearchTerm.value == undefined ? "" : this.producerSearchTerm.value,
            venue: this.venue,
            director: this.directorSearchTerm.value == undefined ? "" : this.directorSearchTerm.value,
            location: this.loglocation,
            other: this.other,
            reelNumber: this.reelNumber,
            showCode: this.showCode,
            airDate: this.airDate == null ? null : this.airDate.toDateString(),
            shootDate: this.shootDate == null ? null : this.shootDate.toDateString(),
            LogType: this.selectedLog,
            searchType: this.searchType,
            StoryCode: this.storyCode,
            shootFrom1: this.shootFrom1 == null ? null : this.shootFrom1.toDateString(),
            shootTo1: this.shootTo1 == null ? null : this.shootTo1.toDateString(),
            shootFrom2: this.shootFrom2 == null ? null : this.shootFrom2.toDateString(),
            shootTo2: this.shootTo2 == null ? null : this.shootTo2.toDateString(),
            shootFrom3: this.shootFrom3 == null ? null : this.shootFrom3.toDateString(),
            shootTo3: this.shootTo3 == null ? null : this.shootTo3.toDateString(),
            shootFrom4: this.shootFrom4 == null ? null : this.shootFrom4.toDateString(),
            shootTo4: this.shootTo4 == null ? null : this.shootTo4.toDateString(),
            showIsDeleted: this.showIsDeleted,
        };

        this.IsDeletedFlag = this.showIsDeleted ? true : false;

        if (this.selectedLog == "Logs") {
            if (this.endSearch == false) {
                this.dashboardService.getdatatape(this.obj, this.limit, this.offset).subscribe((data: Array<object>) => {
                    if (data.length > 0) {
                        data.forEach(obj => {
                            this.datalist.push(obj);
                        });

                        this.datalist.forEach(obj => {
                            obj.locData = this.sanitizer.sanitize(SecurityContext.HTML, obj.location);
                        });

                        this.totalCount = this.datalist[this.datalist.length - 1].totalCount;
                        this.dataSource = new MatTableDataSource(this.datalist);
                        this.dataSource.sort = this.sort;
                        this.IsPrintDisable = true;
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    }
                    else {
                        this.endSearch = true;
                        this.totalCount = 0;
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    }
                },
                    error => {
                        console.log(error);
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    });
                this.offset = this.offset + this.limit;
            }
        }
        else {
            if (this.DataTapeLogendSearch == false) {
                this.dashboardService.getDataTapeByCodeStory(this.obj, this.DataTapeLogLimit, this.DataTapeLogOffset).subscribe((data: Array<object>) => {
                    if (data.length > 0) {
                        data.forEach(obj => {
                            this.datalistDataTapeLogend.push(obj);
                        });
                        this.totalCountLog = this.datalistDataTapeLogend[this.datalistDataTapeLogend.length - 1].totalCount;
                        this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
                        this.dataSourceForDataTapeLog.sort = this.sort;
                        this.IsPrintDisable = false;
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    }
                    else {
                        this.DataTapeLogendSearch = true;
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    }
                },
                    error => {
                        console.log(error);
                        if (loadUi == true) {
                            this.blockUI.stop();
                        }
                    });
                this.DataTapeLogOffset = this.DataTapeLogOffset + this.DataTapeLogLimit;
            }
        }

    }

    GetSelectedLog(val) {

        this.LogDelMultiSelected = false;
        this.LogTextDelMultiSelected = false;

        if (val == "LogsByText" || val == "LogsByCode") {
            this.BytextCode = false; this.searchType = "AND";
        }
        else {
            this.BytextCode = true;
            this.IsPrintDisable = true;
        }
    }

    GetSelectedRow(row) {
        this.dashboardService.jobId = row.id;
        this.NavigateToLog();

        this.router.navigate(['/log']);
    }

    NavigateToLog() {

        this.dashboardDataService.isSearch = false;

        this.dashboardDataService.tapeTitle = this.tapeTitle;
        this.dashboardDataService.loggedBy = this.loggedBy;
        this.dashboardDataService.subtitle = this.subtitle;
        this.dashboardDataService.producer = this.producer;
        this.dashboardDataService.venue = this.venue;
        this.dashboardDataService.director = this.director;
        this.dashboardDataService.location = this.loglocation;
        this.dashboardDataService.logcode = this.logcode;
        this.dashboardDataService.other = this.other;
        this.dashboardDataService.reelNumber = this.reelNumber;
        this.dashboardDataService.showCode = this.showCode;
        this.dashboardDataService.airDate = this.airDate;
        this.dashboardDataService.shootDate = this.shootDate;

        this.dashboardDataService.selectedLog = this.selectedLog;
        this.dashboardDataService.searchType = this.searchType;
        this.dashboardDataService.BytextCode = this.BytextCode;
        this.dashboardDataService.storyCode = this.storyCode;
        this.dashboardDataService.shootFrom1 = this.shootFrom1;
        this.dashboardDataService.shootFrom2 = this.shootFrom2;
        this.dashboardDataService.shootFrom3 = this.shootFrom3;
        this.dashboardDataService.shootFrom4 = this.shootFrom4;
        this.dashboardDataService.shootTo1 = this.shootTo1;
        this.dashboardDataService.shootTo2 = this.shootTo2;
        this.dashboardDataService.shootTo3 = this.shootTo3;
        this.dashboardDataService.shootTo4 = this.shootTo4;
        this.dashboardDataService.showIsDeleted = this.showIsDeleted;

        this.dashboardDataService.limit = this.limit;
        this.dashboardDataService.offset = this.offset;
        this.dashboardDataService.endSearch = this.endSearch;
        this.dashboardDataService.DataTapeLogLimit = this.DataTapeLogLimit;
        this.dashboardDataService.DataTapeLogOffset = this.DataTapeLogOffset;
        this.dashboardDataService.DataTapeLogendSearch = this.DataTapeLogendSearch;
        this.dashboardDataService.datalist = this.datalist;
        this.dashboardDataService.datalistDataTapeLogend = this.datalistDataTapeLogend;

        this.dashboardDataService.totalCount = this.totalCount;
        this.dashboardDataService.totalCountLog = this.totalCountLog;

        this.dashboardDataService.IsPrintDisable = this.IsPrintDisable;
    }

    Print() {
        let dialogRef;
        let objPrint = { searchData: this.dataSourceForDataTapeLog.data, searchParameter: this.obj };
        dialogRef = this.dialog.open(PrintSearchReportComponent, {
            width: '50%',
            height: '80%',
            data: objPrint
        });
    }

    GeneratePdf() {
        this.obj = {
            logCode: this.logcode,
            tapeTitle: this.tapeTitle,
            loggedBy: this.loggedBy,
            subtitle: this.subtitle,
            producer: this.producerSearchTerm.value == undefined ? "" : this.producerSearchTerm.value,
            venue: this.venue,
            director: this.directorSearchTerm.value == undefined ? "" : this.directorSearchTerm.value,
            location: this.loglocation,
            other: this.other,
            reelNumber: this.reelNumber,
            showCode: this.showCode,
            airDate: this.airDate == null ? null : this.airDate.toDateString(),
            shootDate: this.shootDate == null ? null : this.shootDate.toDateString(),
            LogType: this.selectedLog,
            searchType: this.searchType,
            StoryCode: this.storyCode,
            shootFrom1: this.shootFrom1 == null ? null : this.shootFrom1.toDateString(),
            shootTo1: this.shootTo1 == null ? null : this.shootTo1.toDateString(),
            shootFrom2: this.shootFrom2 == null ? null : this.shootFrom2.toDateString(),
            shootTo2: this.shootTo2 == null ? null : this.shootTo2.toDateString(),
            shootFrom3: this.shootFrom3 == null ? null : this.shootFrom3.toDateString(),
            shootTo3: this.shootTo3 == null ? null : this.shootTo3.toDateString(),
            shootFrom4: this.shootFrom4 == null ? null : this.shootFrom4.toDateString(),
            shootTo4: this.shootTo4 == null ? null : this.shootTo4.toDateString(),
        };

        if (this.selectedLog == "LogsByText" || this.selectedLog == "LogsByCode") {

            this.blockUI.start();
            this.dashboardService.GeneratePdfDataTapeList(this.obj, this.totalCountLog).subscribe((data: Array<object>) => {
                if (data.length > 0) {
                    this.DownloadPDF(data);
                    this.blockUI.stop();
                }
                else {
                    this.blockUI.stop();
                }
            },
                error => {
                    console.log(error);
                    this.blockUI.stop();
                });

        }
        else {

            this.blockUI.start();
            this.dashboardService.GeneratePdfDataTapeList(this.obj, this.totalCount).subscribe((data: Array<object>) => {
                if (data != null) {
                    this.DownloadPDF(data);
                    this.blockUI.stop();
                }
                else {
                    this.blockUI.stop();
                }
            },
                error => {
                    console.log(error);
                    this.blockUI.stop();
                });

        }

    }

    DownloadPDF(data) {
        var link = document.createElement("a");
        link.download = data.toString();
        link.href = window.location.protocol + '//' + window.location.hostname + '/report/' + data;
        link.click();
    }

    clear() {
        this.logcode = "";
        this.tapeTitle = "";
        this.loggedBy = "";
        this.subtitle = "";
        this.producer = "";
        this.venue = "";
        this.director = "";
        this.loglocation = "";
        this.other = "";
        this.reelNumber = "";
        this.showCode = "";
        this.airDate = null;
        this.shootDate = null;
        this.selectedLog = "Logs";
        this.searchType = "AND";
        this.BytextCode = true;
        this.IsPrintDisable = true;
        this.storyCode = "";
        this.shootFrom1 = null;
        this.shootFrom2 = null;
        this.shootFrom3 = null;
        this.shootFrom4 = null;
        this.shootTo1 = null;
        this.shootTo2 = null;
        this.shootTo3 = null;
        this.shootTo4 = null;
        this.totalCount = 0;
        this.totalCountLog = 0;
        this.IsMultipleDeleteSelected = false;
        this.LogIds = [];
        this.LogDelMultiSelected = false;
        this.LogTextDelMultiSelected = false;
        this.showIsDeleted = false;
        //clear existing search result
        this.dataSource = new MatTableDataSource<DataTape>();
        this.dataSourceForDataTapeLog = new MatTableDataSource<DataTapeLog>();
    }

    onMultipleDeleteSelection(Type, Flag) {
        this.IsMultipleDeleteSelected = true;

        if (Type == "Log") {
            if (Flag == "single") {
                var count = this.datalist.filter((obj) => obj.IsSelected == true).length;
                if (count == 0) {
                    this.LogDelMultiSelected = false;
                }
            } else if (Flag == "multiple") {
                if (this.datalist != undefined && this.datalist != null) {
                    this.datalist.forEach(row => {
                        if (this.LogDelMultiSelected == false) {
                            row.IsSelected = true;
                        } else {
                            row.IsSelected = false;
                        }
                    });
                }
            }
        } else if (Type == "LogText") {
            if (Flag == "single") {
                var count = this.datalistDataTapeLogend.filter((obj) => obj.IsSelected == true).length;
                if (count == 0) {
                    this.LogTextDelMultiSelected = false;
                }
            } else if (Flag == "multiple") {
                if (this.datalistDataTapeLogend != undefined && this.datalistDataTapeLogend != null) {
                    this.datalistDataTapeLogend.forEach(row => {
                        if (this.LogTextDelMultiSelected == false) {
                            row.IsSelected = true;
                        } else {
                            row.IsSelected = false;
                        }
                    });
                }
            }
        }
    }

    RestoreLog(row) {
        /* Catch selected Id's */
        var IDS = [];
        if (this.selectedLog == "Logs") {
            if (this.datalist != undefined && this.datalist != null) {
                this.datalist.forEach(row => {
                    if (row.IsSelected == true) {
                        IDS.push(row.id);
                    }
                });
            }
        } else {
            if (this.datalistDataTapeLogend != undefined && this.datalistDataTapeLogend != null) {
                this.datalistDataTapeLogend.forEach(row => {
                    if (row.IsSelected == true) {
                        IDS.push(row.id);
                    }
                });
            }
        }
        if (IDS.length == 0) {
            IDS.push(row.id);
        }

        /* Catch selected Id's */

        this.alertService.OpenConfirm('Are you sure you want to restore the Log(s)?', true, "DataTape Detail Confirmation").then(x => {
            if (x == true) {
                this.blockUI.start();
                this.dashboardService.RestoreDataTape(IDS).subscribe((data) => {
                    if (data == true) {
                        if (this.selectedLog == "Logs") {
                            this.datalist = [];
                            this.dataSource = new MatTableDataSource(this.datalist);
                            this.offset = this.offset - this.limit;
                            this.getTapeData(true);
                        }
                        else {
                            this.datalistDataTapeLogend = [];
                            this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
                            this.DataTapeLogOffset = this.DataTapeLogOffset - this.DataTapeLogLimit;
                            this.getTapeData(true);
                        }
                        this.toastr.success('Log(s) restored Successfully');
                    }
                    this.blockUI.stop();
                },
                    error => {
                        this.toastr.error(error);
                        this.blockUI.stop();
                    });
            }
        });
    }

    DeleteLog(row) {
        /* Catch selected Id's */
        var IDS = [];
        if (this.selectedLog == "Logs") {
            if (this.datalist != undefined && this.datalist != null) {
                this.datalist.forEach(row => {
                    if (row.IsSelected == true) {
                        IDS.push(row.id);
                    }
                });
            }
        } else {
            if (this.datalistDataTapeLogend != undefined && this.datalistDataTapeLogend != null) {
                this.datalistDataTapeLogend.forEach(row => {
                    if (row.IsSelected == true) {
                        IDS.push(row.id);
                    }
                });
            }
        }
        if (IDS.length == 0) {
            IDS.push(row.id);
        }

        /* Catch selected Id's */

        this.alertService.OpenConfirm('Are you sure you want to delete the Log?', true, "DataTape Detail Confirmation").then(x => {
            if (x == true) {
                this.blockUI.start();
                this.dashboardService.DeleteDataTape(IDS).subscribe((data) => {
                    if (data == true) {
                        if (this.selectedLog == "Logs") {
                            this.datalist = [];
                            this.dataSource = new MatTableDataSource(this.datalist);
                            this.offset = this.offset - this.limit;
                            this.getTapeData(true);
                        }
                        else {
                            this.datalistDataTapeLogend = [];
                            this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
                            this.DataTapeLogOffset = this.DataTapeLogOffset - this.DataTapeLogLimit;
                            this.getTapeData(true);
                        }
                        this.toastr.success('Logs deleted Successfully');
                    }
                    this.blockUI.stop();
                },
                    error => {
                        this.toastr.error(error);
                        this.blockUI.stop();
                    });
            }
        });
    }

    onScroll() {
        this.getTapeData(false);
    }

    onScrollDataTapeLog() {
        this.getTapeData(false);
    }

    getWindowHeight = function () {
        var windowHeight = $(window).innerHeight();
        this.windowMaxHeight = windowHeight - 220;
    };

    getWindowWidth = function () {
        var windowWidth = $(window).innerWidth();
        this.windowMaxWidth = windowWidth - 200;
    };

    sortGrid(sortBy) {
        if (sortBy == 'AirDate') {
            if (this.sortAirDateLogs === true) {
                this.datalist.sort(function (a, b) {
                    var dateAirFirst = new Date(a.aiR_DATE);
                    var dateAirSecond = new Date(b.aiR_DATE);
                    return dateAirFirst > dateAirSecond ? -1 : 1;
                });
                this.sortAirDateLogs = false;
            }
            else {
                this.datalist.sort(function (a, b) {
                    var dateAirFirst = new Date(a.aiR_DATE);
                    var dateAirSecond = new Date(b.aiR_DATE);
                    return dateAirFirst > dateAirSecond ? 1 : -1;
                });
                this.sortAirDateLogs = true;
            }

            this.dataSource = new MatTableDataSource(this.datalist);
        }
        else if (sortBy == 'ShootDate') {
            if (this.sortShootDate === true) {
                this.datalist.sort(function (a, b) {
                    var dateShootFirst = new Date(a.shooT_DATE);
                    var dateShootSecond = new Date(b.shooT_DATE);
                    return dateShootFirst > dateShootSecond ? -1 : 1;
                });
                this.sortShootDate = false;
            }
            else {
                this.datalist.sort(function (a, b) {
                    var dateShootFirst = new Date(a.shooT_DATE);
                    var dateShootSecond = new Date(b.shooT_DATE);
                    return dateShootFirst > dateShootSecond ? 1 : -1;
                });
                this.sortShootDate = true;
            }

            this.dataSource = new MatTableDataSource(this.datalist);
        }
        else if (sortBy == 'AirDateTextOrCode') {
            if (this.sortAirDateTextOrCode === true) {
                this.datalistDataTapeLogend.sort(function (a, b) {
                    var dateAirFirst = new Date(a.aiR_DATE);
                    var dateAirSecond = new Date(b.aiR_DATE);
                    return dateAirFirst > dateAirSecond ? -1 : 1;
                });
                this.sortAirDateTextOrCode = false;
            }
            else {
                this.datalistDataTapeLogend.sort(function (a, b) {
                    var dateAirFirst = new Date(a.aiR_DATE);
                    var dateAirSecond = new Date(b.aiR_DATE);
                    return dateAirFirst > dateAirSecond ? 1 : -1;
                });
                this.sortAirDateTextOrCode = true;
            }

            this.dataSourceForDataTapeLog = new MatTableDataSource(this.datalistDataTapeLogend);
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.key === "Enter") {
            this.getSearch();
        }
    }

}

export interface DataTape {
    id: number;
    loG_CODE: string;
    totalLogs: string;
    tapE_TITLE: string;
    subtitle: string;
    location: string;
    locData: string;
    aiR_DATE: Date;
    shooT_DATE: Date;
    reeL_NUMBER: string;
    director: string;
    producer: string;
    loggeD_BY: string;
    other: string;
    showCode: string;
    venue: string;
    Isdeleted: boolean;
    dataLogs: DataLog[];
}

export interface DataLog {
    id: number;
    jobId: number;
    time: string;
    code: string;
    story: string;
}

export interface DataTapeLog {
    loG_CODE: string;
    totalLogs: string;
    tapE_TITLE: string;
    aiR_DATE: Date;
    id: number;
    time: string;
    code: string;
    story: string;
}