import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class DashboardService {
    apiUrl: string = "api/datatape/";
    jobId: any;
    constructor(private _http: HttpClient) {

    }

    getdatatape(obj: any, limit, offset, ) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.post(this.apiUrl + 'getdatatape?limit=' + limit + '&offset=' + offset, obj);
    }

    getDataTapeByCodeStory(obj: any, limit, offset) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.post(this.apiUrl + 'GetDataTapeByCodeStory?limit=' + limit + '&offset=' + offset, obj);
    }

    DeleteDataTape(jobIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.post(this.apiUrl + 'DeleteDataTape', jobIds, { headers: headers });
    }

    RestoreDataTape(jobIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.post(this.apiUrl + 'RestoreDataTape', jobIds, { headers: headers });
    }

    Search(searchText: any, searchColumn: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.get(this.apiUrl + 'Search?searchText=' + searchText + '&searchColumn=' + searchColumn);
    }
    GeneratePdfDataTapeList(obj: any, totRecord) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this._http.post(this.apiUrl + 'GeneratePdfDataTapeList?totRecord=' + totRecord, obj);
    }
}
